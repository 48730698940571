:root {
  --lightColor1: rgb(253, 235, 255);
  --lightColor2: rgb(215, 255, 245);
  --mediumColor1: rgb(246, 152, 255);
  --mediumColor1T: rgba(246, 152, 255, 0.8);
  --mediumColor2: rgb(152, 255, 229);
  --mediumColor2T: rgba(152, 255, 229, 0.8);
  --highlightColor1: rgb(234, 0, 255);
  --highlightColor2: rgb(0, 255, 191);

  --background-color-light: wheat; 
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  line-height: 1.65;
  font-weight: 400;
  font-family: 'PT Serif', serif;
  word-wrap: break-word;
}

html {
  background-color: var(--background-color-light);
}

.main {
  max-width: 1000px;
  margin: auto;
  width: 90%;
}

.rand-bible {
  min-height: 60vh;
  justify-content: center;
}

.rand-bible p {
  font-size: 20px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  font-family: 'Roboto Mono', monospace;
}

.description {
  min-height: 40vh;
}

.description p {
  margin: 20px 0;
}

h2 {
  text-align: center;
  padding: 50px;
}

form{
  text-align: center;
  margin: 20px 0;
}

input {
  background-color: white;
  cursor: pointer;
  padding: 10px 20px;
  width: 130px;
  border-radius: 10px;
  margin: 5px;
  border: 3px solid black;
}

input:hover {
  background-color: wheat;
}

footer {
  text-align: center;
}

footer a {
  color: tomato;
}